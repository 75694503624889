<template>
  <v-card v-if="hostId">
    <v-toolbar height="72px" v-if="hostId">
      
      <v-btn fab dark color="green lighten-1" to="/">
        <v-icon x-large>home</v-icon>
      </v-btn>
      <!--  
      <v-spacer></v-spacer>
      <v-badge color="red" overlap bottom right>
        <span slot="badge" v-if="notifications.length > 0">{{notifications.length}}</span>
        <v-menu :nudge-width="100">

          <template v-slot:activator="{ on }">
            <v-btn color="green lighten-1" fab dark v-on="on">
              <v-icon x-large>calendar_month</v-icon>
            </v-btn>
          </template>

          <v-list v-if="notifications.length > 0">
            <v-list-item-group
              color="primary"
            >        
              <v-list-item v-for="notification in notifications" :key="notification.bookingId">
                <v-list-item-icon>
                  <v-btn icon ripple @click="pickNotification(notification)">
                    <v-icon color="green">play_circle_filled</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>      
                  <v-list-item-title>{{notification.checkInDate}}</v-list-item-title>
                  <v-list-item-subtitle>{{notification.reservationCode + ' ' + notification.guestName}}</v-list-item-subtitle>
                </v-list-item-content>
              
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-badge>
      -->
      <v-spacer></v-spacer>
      <v-btn fab dark color="green lighten-1" to="/calendars">
        <v-icon x-large>calendar_month</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn fab dark color="green lighten-1" to="/host">
        <v-icon x-large>settings</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn fab dark color="green lighten-1" to="/about">
        <v-icon x-large>help_outline</v-icon>
      </v-btn>

<!--       <v-spacer></v-spacer>
      <v-btn fab dark color="green lighten-1" @click.native="toggleFullScreen">
        <v-icon x-large>zoom_out_map</v-icon>
      </v-btn>
 -->
      <v-spacer></v-spacer>
      <v-menu :nudge-width="100">


        <template v-slot:activator="{ on }">
          <v-btn color="green lighten-1" fab dark v-on="on">
            <v-icon x-large>language</v-icon>
          </v-btn>
        </template>  
        <v-list>
          <v-list-item-group
            color="primary"
          >        
            <v-list-item
              v-for="item in languages"
              :key="item"
              @click="switchLocale(item)"
            >
              <v-list-item-icon>
                <flag :iso="item" />
              </v-list-item-icon>
              <v-list-item-content>      
                <v-list-item-title>{{ $t( "menu." + item) }}</v-list-item-title>
              </v-list-item-content>
            
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <v-btn fab dark color="green lighten-1" @click.native="logout">
        <v-icon x-large>exit_to_app</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import FlagIcon from 'vue-flag-icon'
import * as utils from '@/utils'
import store from '@/store'

Vue.use(FlagIcon)

export default {
  name: 'Menu',
  data: () => ({
    languages: [
      'gb', 'hk', 'jp'
    ],
    dialog: false,
  }),
  created () {
  },
  mounted () {
    console.log('hostId', this.hostId)
  },
  computed: mapState({
    hostId: state => state.session.hostId,
    notifications: state => state.host.notifications
  }),
  methods: {
    async pickNotification (notification) {
      console.log('pickNotification', notification)

      const reservationCode = notification.reservationCode
      const identityId = notification.identityId

      await this.removeNotification(notification)

      this.gotoMembers(notification)
    },
    switchLocale (item) {
      console.log('switchLocale', item)
      this.$i18n.locale = item
    },
    toggleFullScreen() {
      try {
        utils.ToggleFullScreen()  
      } 
      catch (e) {
        window.alert(e)
      }
      
    },
    ...mapActions({
      logout: 'session/logout',
      gotoMembers: 'host/gotoMembers',
      removeNotification: 'host/removeNotification',
    })
  },
}
</script>